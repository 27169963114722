<template>
<div>
  <div class="header">
    <h1>Projects</h1>
    <font-awesome-icon v-if="projectDataLoading" :icon="['fas', 'spinner']" class="loading-component"/>
  </div>
  <div class="project-select">
    <div v-if="projects !== null">
      <div class="project-select-items" v-for="project, index in projects" :key="index">
        <span>{{project.name}}</span>
        <input type="checkbox" @click="handleSelectProject(project)" :checked="project.active"/>
      </div>
    </div>
  </div>
  <table id="project-data-table">
        <thead>
          <tr>
            <th>Project</th>
            <th>Total Workflows</th>
            <th>Active</th>
            <th>Paused</th>
            <th>Pending</th>
            <th>Last Update</th>
            <th></th>
          </tr>
        </thead>
        <tbody v-show="projectData !== null">        
          <tr v-for="pr, index in projectData" :key="index">
            <td><router-link :to="{ name: 'Workflows', params: {projectName: pr.name}}">{{pr.name}}</router-link></td>        
            <td>{{pr.total}}</td>
            <td>{{pr.active}}</td>
            <td>{{pr.paused}}</td>   
            <td>{{pr.pending}}</td>
            <td>{{showDate(pr.maxDate)}}</td>
            <td><router-link target="_blank" :to="{ name: 'Portal', params: {projectName: pr.name} }">Portal</router-link></td>
          </tr>
        </tbody>
      </table>
</div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import SelectList from '../components/Generics/SelectList.vue'
import displayDate from "../utils/dateHandler.js" 

export default {
  name: 'Projects',
  components: {SelectList},
  computed: {
    ...mapGetters({
      projects: "getProjects",
      projectData: "getProjectData",
      projectDataLoading: "projectDataLoading"
    })
  },
  methods: {
    ...mapActions(['loadProjectMetadata', 'loadProjects']),
    handleSelectProject(project){
      project.active = !project.active
      this.loadProjectMetadata(project)
    },
    showDate(date){
      let result = displayDate(date)
      if(result==="Invalid Date"){
        result=""
      }
      return result
    },
  },
  async mounted () {
    await this.loadProjects()
    await this.projects.forEach(p => this.loadProjectMetadata(p))
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.project-select{
  margin:auto;
  text-align: center;
}
.project-select-items{
  display: inline-flex;
  justify-content: center;
  margin: 1rem;  
}

.app-middle{
  text-align: center;
}

.loading-component {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
}
</style>
